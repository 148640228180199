@import 'src/app/styles/custom.scss';

.provider {
  width: 125px;
  border-radius: 0;
}

.collapse {
  display: none;
  transition: display 0.3s ease;
}

.collapse.show {
  display: block;
}
